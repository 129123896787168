import React from 'react'

const CuadroEquipoLista = ( {property}) => {
  return (
    <div className='bg-blue-600 flex gap-4 p-4 text-white w-[90%] mx-auto hover:bg-[#3c3583] shadow-lg shadow-blue-500/50'>
       <img src={`https://limegreen-coyote-441208.hostingersite.com/img/${property.logo}`} alt="" className='h-[80px] w-[80px]' />
        <div className='my-auto'>
          <h1 className='text-3xl font-semibold'>{property.nombre_equipo} {property.genero_equipo}</h1>
          <p className='text-lg'>{property.ciudad_equipo}</p>
        </div>
    </div>
  )
}

export default CuadroEquipoLista