import React, { useEffect, useState } from 'react';
import Navbar from '../components/Navbar'
import CuadroListaPartido from '../components/CuadroListaPartido';
import { Link } from 'react-router-dom';
import Footer from '../components/Footer';

const Partidos = () => {

  const [properties, setProperties] = useState([]);
  
  // Función para cargar las propiedades desde la API
  const fetchProperties = () => {
    fetch('https://limegreen-coyote-441208.hostingersite.com/api/apiPartidos.php') // Cambiar a la URL correcta de tu servidor PHP
      .then(response => response.json())
      .then(data => setProperties(data))
      .catch(error => console.error('Error fetching data:', error));
  };

  // Cargar las propiedades inicialmente al montar el componente
  useEffect(() => {
    fetchProperties();

    // Configurar la actualización cada 5 segundos
    const interval = setInterval(() => {
      fetchProperties();
    }, 5000);

    // Limpiar el intervalo al desmontar el componente
    return () => clearInterval(interval);
  }, []);



  return (
    <div className='w-full h-full'>
      <Navbar/>
     

      <div className="mt-[120px] mb-20 mx-auto w-full h-full flex flex-col gap-4">
        <h1 className='text-2xl text-center font-semibold'>Partidos Siguientes</h1>
        
          {properties && properties.filter((property) => property.estado_partido === "0").map(property => (
              <Link key={property.id} to={`/partidos/${property.id}`}>
              <CuadroListaPartido property={property} />
            </Link>
          ))}
        

      </div>
      <div className="mt-[120px] mb-20 mx-auto w-full h-full flex flex-col gap-4">
        <h1 className='text-2xl text-center font-semibold'>Partidos Terminados</h1>
        
          {properties && properties.filter((property) => property.estado_partido === "1").map(property => (
              <Link key={property.id} to={`/partidos/${property.id}`}>
              <CuadroListaPartido property={property} />
            </Link>
          ))}
        

      </div>

      <Footer/>
    </div>
  )
}

export default Partidos