import React from 'react'
import {
    FaDribbbleSquare,
    FaFacebookSquare,
    FaGithubSquare,
    FaInstagram,
    FaTwitterSquare
} from 'react-icons/fa'

const Footer = () => {
  return (
    <div className='bg-[#000300] text-white max-w-[1240px] mx-auto py-16 px-4 grid lg:grid-cols-3 gap-8'>
        <div>
            <div className='w-full text-3xl font-bold flex'>        
                <h1 className='text-[#286cda]'>cuartavoleibol</h1>
            </div>
            <p className='py-4'>Vóleibol Cuarta Región de Chile!</p>
            <div className='flex justify-between md:w-[75%] my-6'>
                <FaFacebookSquare size={30}/>
                <FaInstagram size={30}/>
                <FaTwitterSquare size={30}/>
                <FaGithubSquare size={30}/>
                <FaDribbbleSquare size={30}/>
            </div>
        </div>
        <div className='lg:col-span-2 flex justify-between mt-6'>
            <div>
                <h6 className='font-medium'>Soporte</h6>
                <ul>
                    <li className='py2 text-sm'>Precio</li>
                    <li className='py2 text-sm'>Documentación</li>
                    <li className='py2 text-sm'>Guías</li>
                    <li className='py2 text-sm'>Mantenimiento</li>
                </ul>
            </div>
            <div>
                <h6 className='font-medium'>Agencia</h6>
                <ul>
                    <li className='py2 text-sm'>Nosotros</li>
                    <li className='py2 text-sm'>Blog</li>
                    <li className='py2 text-sm'>Trabajos</li>
                    <li className='py2 text-sm'>Puestos</li>
                </ul>
            </div>
            <div>
                <h6 className='font-medium'>Legalidad</h6>
                <ul>
                    <li className='py2 text-sm'>Reclamos</li>
                    <li className='py2 text-sm'>Política</li>
                    <li className='py2 text-sm'>Términos</li>
                </ul>
            </div>
        </div>
    </div>
  )
}

export default Footer