import React from 'react'

const CuadroListaPartido = ({ property }) => {
  return (
    <div className='bg-blue-600 flex justify-between p-1 text-white w-[90%] mx-auto hover:bg-[#3c3583] shadow-lg shadow-blue-500/50'>
        
        
        <div className='flex justify-between w-full  md:ml-10 gap-4'>
            <p className='my-auto text-[0.8rem] font-bold'>{property.fecha_partido}</p>
            <div className=' flex flex-col gap-2 w-full'>
                <div className='flex justify-between'>
                  <div className='flex gap-2'>
                    <img src={`https://limegreen-coyote-441208.hostingersite.com/img/${property.logo_equipo_uno}`} alt="" className='h-[40px] w-[40px] border-solid border-4 border-gray-600'/>
                    <p className='my-auto  text-md'>{property.siglas_equipo_uno} {property.genero}</p>
                  </div>
                  <p className='my-auto  font-semibold text-lg'>{property.sets_equipo_uno}</p>
                </div>
                <div className='flex justify-between w-full'>
                  <div  className='flex gap-2'>
                    <img src={`https://limegreen-coyote-441208.hostingersite.com/img/${property.logo_equipo_dos}`} alt="" className='h-[40px] w-[40px] border-solid border-4 border-gray-600'/>
                    <p className='my-auto  text-md'>{property.siglas_equipo_dos} {property.genero}</p>
                  </div>
                  <p className='my-auto  font-semibold text-lg'>{property.sets_equipo_dos}</p>
                </div>
            </div>
        </div>


    </div>
  )
}

export default CuadroListaPartido