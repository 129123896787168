import { Link } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import './App.css';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import CuadroPartidoInicio from './components/CuadroPartidoInicio';
import Contacto from './components/Contacto';

function App() {

  const [properties, setProperties] = useState([]);
  
  // Función para cargar las propiedades desde la API
  const fetchProperties = () => {
    fetch('https://limegreen-coyote-441208.hostingersite.com/api/apiPartidos.php') // Cambiar a la URL correcta de tu servidor PHP
      .then(response => response.json())
      .then(data => {
        console.log('Datos de la API:', data); // Verifica la estructura aquí
        setProperties(data);
      })
      .catch(error => console.error('Error fetching data:', error));
  };

  // Cargar las propiedades inicialmente al montar el componente
  useEffect(() => {
    fetchProperties();

    // Configurar la actualización cada 5 segundos
    const interval = setInterval(() => {
      fetchProperties();
    }, 5000);

    // Limpiar el intervalo al desmontar el componente
    return () => clearInterval(interval);
  }, []);


  return (
    <div className='bg-gray-800'>
      <Navbar/>

      <div className='flex flex-col-reverse md:flex-row mt-[100px] pt-6 md:w-[80%] mx-auto justify-center gap-10 bg-gray-700 drop-shadow-lg shadow-lg shadow-black text-white'>
        <div className='flex flex-col-reverse md:flex-col'>
          <iframe className='w-full md:w-[500px]' height="315" src="https://www.youtube.com/embed/4-MhdUCQIWc?si=0-farFJkXBsEzyc_" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
          <div className='flex justify-center text-center'>
            <a href="https://limegreen-coyote-441208.hostingersite.com/inscribir_equipo.php" className='bg-[#db0000] hover:bg-blue-500 w-[90%] py-4 text-xl mb-4 md:mt-4 drop-shadow-lg shadow-lg shadow-black hover:shadow-blue-950'>Inscribir Club/Equipo</a>
          </div>
        </div>

        <div className='md:mb-20 gap-1 flex flex-col md:flex-row'>
        <h2 className='text-center text-xl font-semibold md:hidden flex mx-auto'>Partidos Siguientes</h2>
          <div className='flex md:flex-col gap-1 overflow-scroll w-[96%] mx-auto md:overflow-hidden'>
          <h2 className='text-center text-xl font-semibold hidden md:flex'>Partidos Siguientes</h2>
                {properties && properties.filter((property) => property.estado_partido === "0").slice(0,10).map(property => (
                      <Link key={property.id} to={`/partidos/${property.id}`}>
                      <CuadroPartidoInicio property={property} />
                    </Link>
                  ))}
          </div>
          <h1 className='text-center text-xl font-semibold md:hidden flex mx-auto'>Partidos Terminados</h1>
          <div className='flex md:flex-col gap-1 overflow-scroll w-[96%] mx-auto  md:overflow-hidden'>
          <h1 className='text-center text-xl font-semibold hidden md:flex'>Partidos Terminados</h1>
                {properties && properties.filter((property) => property.estado_partido === "1").map(property => (
                      <Link key={property.id} to={`/partidos/${property.id}`}>
                      <CuadroPartidoInicio property={property} />
                    </Link>
                  ))}
          </div>
        </div>
      </div>


      <Contacto/>

      <Footer/>
      
    </div>
  );
}

export default App;
