import React from 'react'

const CuadroPartidoInicio = ({ property }) => {
  return (
    <div className='bg-gray-800 w-[200px] h-[70px] hover:bg-gray-600 items-center my-auto hover:shadow-sm hover:shadow-[#ffffff] hover:scale-105 duration-300'>
        <div className='flex flex-col justify-center gap-1 h-full'>
            <div className='flex gap-2 items-center pl-2'>
              <img src={`https://limegreen-coyote-441208.hostingersite.com/img/${property.logo_equipo_uno}`} alt="" className='h-[30px] w-[30px]' />
              <div className='flex justify-between w-full pr-2'>
                <h1>{property.siglas_equipo_uno} {property.genero}</h1>
                <p>{property.sets_equipo_uno}</p>
              </div>
            </div>
            <div className='flex gap-2 items-center pl-2'>
              <img src={`https://limegreen-coyote-441208.hostingersite.com/img/${property.logo_equipo_dos}`} alt="" className='h-[30px] w-[30px] '/>
              <div className='flex justify-between w-full pr-2'>
                <h1>{property.siglas_equipo_dos} {property.genero}</h1>
                <p>{property.sets_equipo_dos}</p>
              </div>
            </div>
        </div>
    </div>
  )
}

export default CuadroPartidoInicio