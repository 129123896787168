import React, { useEffect, useState } from 'react';
import Navbar from '../components/Navbar'
import { Link } from 'react-router-dom';
import Footer from '../components/Footer';
import CuadroCampeonatoLista from '../components/CuadroCampeonatoLista';

const Campeonatos = () => {


  const [properties, setProperties] = useState([]);
  
  // Función para cargar las propiedades desde la API
  const fetchProperties = () => {
    fetch('https://limegreen-coyote-441208.hostingersite.com/api/apiCampeonatos.php') // Cambiar a la URL correcta de tu servidor PHP
      .then(response => response.json())
      .then(data => setProperties(data))
      .catch(error => console.error('Error fetching data:', error));
  };

  // Cargar las propiedades inicialmente al montar el componente
  useEffect(() => {
    fetchProperties();

    // Configurar la actualización cada 5 segundos
    const interval = setInterval(() => {
      fetchProperties();
    }, 5000);

    // Limpiar el intervalo al desmontar el componente
    return () => clearInterval(interval);
  }, []);



  return (
    <div>
    <Navbar/>
      

      <div className='flex flex-col gap-3 mt-[120px] mb-20'>
        <div className='flex flex-col gap-3'>
          <h1 className='text-center text-xl font-semibold'>Campeonatos</h1>
        {properties && properties.map(property => (
                <Link key={property.id} to={`/campeonatos/${property.id}`}>
                <CuadroCampeonatoLista property={property} />
              </Link>
            ))}
        </div>

      </div>

      <Footer/>
    </div>
  )
}

export default Campeonatos