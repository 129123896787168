import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Navbar from '../components/Navbar'
import Footer from '../components/Footer';

const VistaEquipo = () => {

  const { id } = useParams(); // Obtener el id de la URL
  const [equipo, setEquipo] = useState(null);

  useEffect(() => {
    // Llamar a la API con el id del partido
    fetch(`https://limegreen-coyote-441208.hostingersite.com/api/apiEquipos.php?id=${id}`)
      .then(response => response.json())
      .then(data => {
        // Si la respuesta es un array, tomar el primer elemento
        if (Array.isArray(data) && data.length > 0) {
          setEquipo(data[0]);
        }
      })
      .catch(error => {
        console.error('Error fetching team data:', error);
      });
  }, [id]);

  if (!equipo) {
    return <div>Loading...</div>;
  }



  return (
    <div>
      <Navbar/>
      <div className='mt-[120px] mb-20 w-[90%] mx-auto'>

        <div className='flex gap-10 min-h-[20vh]'>
          <img src={`https://limegreen-coyote-441208.hostingersite.com/img/${equipo.logo}`} alt="" className='h-[100px] w-[100px]' />
          <div>
            <h1 className='text-3xl font-semibold'>{equipo.nombre_equipo} {equipo.genero_equipo}</h1>
            <p className='text-xl'>{equipo.ciudad_equipo}</p>
          </div>
        </div>
        <hr />

        

      </div>

      <Footer/>
    </div>
  )
}

export default VistaEquipo