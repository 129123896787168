import React, {useState} from 'react'
import {AiOutlineClose, AiOutlineMenu} from 'react-icons/ai'
import { Link } from 'react-router-dom'
import Logo from '../assets/LogoPNG.png'

const Navbar = () => {
    const [nav, setNav] = useState(true)

    const handleNav = () => {
        setNav(!nav)
    }

  return (
    <div className='flex justify-between items-center bg-[#000300] w-full fixed top-0 z-50 h-[100px] mx-auto px-4 text-white shadow-lg shadow-[#242724]'>
        <div >
            <Link className='w-full text-3xl font-semibold flex  max-h-[80px] gap-4'  to='/'>
                <img src={Logo} alt="" className='h-[80px]'/>
                <h1 className='hover:text-[#286cda] my-auto md:text-[2.5rem] hover:scale-105 duration-300'>cuartavoleibol</h1>
            </Link>        

        </div>
        <ul className='hidden md:flex'>
            <li className='p-4 hover:scale-105 duration-300'><Link to="/partidos">Partidos</Link></li>
            <li className='p-4 hover:scale-105 duration-300'><Link to="/equipos">Equipos</Link></li>
            <li className='p-4 hover:scale-105 duration-300'><Link to="/campeonatos">Campeonatos</Link></li>
            <li className='p-4 hover:scale-105 duration-300'><Link to="/panel">Panel</Link></li>
        </ul>


        <div onClick={handleNav} className='block md:hidden'>
            {!nav ? <AiOutlineClose size={20}/> : <AiOutlineMenu size={20} />}
            
        </div>


        <div className={!nav ? 'fixed left-0 top-0 w-[60%] h-full border-r border-r-gray-900 bg-[#000300] ease-in-out duration-500' : 'fixed left-[-100%]'}>

            <div className='w-full text-3xl font-bold flex m-4'>        
                <a className='w-full text-3xl font-bold flex hover:scale-105 duration-300' href="#inicio">
                <img src={Logo} alt="" className='h-[80px]'/>
                </a>   
            </div>
            <ul className='p-4 uppercase'>
            <Link to='/partidos' onClick={handleNav}><li className='p-4 hover:text-[#00df9a]  border-b border-gray-600 '>Partidos</li></Link>
                <Link to='/equipos' onClick={handleNav}><li className='p-4 hover:text-[#00df9a]  border-b border-gray-600 '>Equipos</li></Link>
                <Link to='/campeonatos' onClick={handleNav}><li className='p-4 hover:text-[#00df9a]  border-b border-gray-600'>Campeonatos</li></Link>
                <Link to='/panel' onClick={handleNav}><li className='p-4 hover:text-[#00df9a] '>Panel</li></Link>
                
            </ul>
        </div>
    </div>
  )
}

export default Navbar