import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import Partidos from './Vistas/Partidos';
import Equipos from './Vistas/Equipos';
import VistaPartido from './Vistas/VistaPartido';
import VistaEquipo from './Vistas/VistaEquipo';
import Campeonatos from './Vistas/Campeonatos';
import VistaCampeonato from './Vistas/VistaCampeonato';
import RedirectToExternal from './components/RedirectToExternal';

const root = ReactDOM.createRoot(document.getElementById('root'));

const router = createBrowserRouter([
  {
    path: "/",
    element: <App/>
  },
  {
    path: "/partidos",
    element: <Partidos/>
  },
  {
    path: "/partidos/:id",
    element: <VistaPartido />
  },
  {
    path: "/equipos",
    element: <Equipos/>
  },
  {
    path: "/equipos/:id",
    element: <VistaEquipo />
  },
  {
    path: "/campeonatos",
    element: <Campeonatos/>
  },
  {
    path: "/campeonatos/:id",
    element: <VistaCampeonato />
  },
  {
    path: "/panel",
    element: <RedirectToExternal url="https://limegreen-coyote-441208.hostingersite.com/" />
  }
])


root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);
