import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Navbar from '../components/Navbar'
import Footer from '../components/Footer';

const VistaPartido = () => {

  const { id } = useParams(); // Obtener el id de la URL
  const [match, setMatch] = useState(null);

  useEffect(() => {
    // Llamar a la API con el id del partido
    fetch(`https://limegreen-coyote-441208.hostingersite.com/api/apiPartidos.php?id=${id}`)
      .then(response => response.json())
      .then(data => {
        // Si la respuesta es un array, tomar el primer elemento
        if (Array.isArray(data) && data.length > 0) {
          setMatch(data[0]);
        }
      })
      .catch(error => {
        console.error('Error fetching match data:', error);
      });
  }, [id]);

  if (!match) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <Navbar />

      <div className='mt-[100px] pt-[20px] mb-[20px] p-2 w-[90%] mx-auto drop-shadow-lg shadow-lg shadow-black'>
        
        <div className='flex justify-between w-[90%] mx-auto mb-3'>
          <div className='flex flex-col'>
            <p className='text-blue-900 font-semibold'>{match.lugar}</p>
            <p className='font-semibold'>{match.genero}</p>
          </div>
          <div className='flex flex-col justify-center items-center font-bold min-w-[86px]'>
            <p className='text-[0.8rem]'>{match.fecha_partido}</p>
            <p>{match.hora_partido.slice(0,5)}</p>
          </div>
        </div>
        
        <div className='flex justify-between w-[70%] mx-auto'>
          <div className='flex items-center gap-1'>
            <div className='flex flex-col justify-center items-center'>
              <img src={`https://limegreen-coyote-441208.hostingersite.com/img/${match.logo_equipo_uno}`} alt="" className='h-[100px] w-[100px] min-h-[100px] min-w-[100px] border-solid border-4 border-gray-600' />
              <p  className='text-2xl font-bold'>{match.siglas_equipo_uno}</p>
            </div>
            <p className='text-3xl font-bold'>{match.sets_equipo_uno}</p>
          </div>
          <p className='text-3xl font-bold my-auto'>-</p>
          <div className='flex items-center gap-1'>
            <p className='text-3xl font-bold'>{match.sets_equipo_dos}</p>
            <div  className='flex flex-col justify-center items-center'>
              <img src={`https://limegreen-coyote-441208.hostingersite.com/img/${match.logo_equipo_dos}`} alt="" className='h-[100px] w-[100px] min-h-[100px] min-w-[100px]  border-solid border-4 border-gray-600' />
              <p className='text-2xl font-bold'>{match.siglas_equipo_dos}</p>
            </div>
          </div>
        </div>
        
      </div>


      <Footer />

    </div>
  )
}

export default VistaPartido